import $ from 'jquery';

function requireAll(r) {
  r.keys().forEach(r);
}
// load the svgs to trigger webpack process
requireAll(require.context('../icons/', true, /\.svg$/));





$(document).ready(function () {
  function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      //CHECK IF PARTIALY VISIBLE
      rect.top < window.innerHeight && rect.bottom >= 0
    );
  }

  var footer = document.querySelector('.footer');
  var down = document.querySelector('.down');

  setInterval(() => {
    if(isInViewport(footer)) {
      down.style.display = 'none';
    } else {
      down.style.display = 'block';
    }
  }, "500")
})

